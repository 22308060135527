<script>
</script>


<template>
  <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
            <img class="logo" :alt="'さあ歴史を見つけよう BYGO'" src="https://storage.googleapis.com/history-d8428.appspot.com/base/logo_w1200.png" width="200" height="36"/>
        </a>
        <ul class="nav col-12 col-lg-auto  mb-2 justify-content-center mb-md-0 right" >
          <li><a href="https://bygo.jp/service" class="nav-link px-2 text_en">使ってみる</a></li>
          <li><a href="https://form.run/@bygo-contact" class="nav-link px-2 text_en">Contact</a></li>
        </ul>
      </div>
  </div>

</template>